
.view_data {
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  .el-card {
    flex: 1;
    padding: 30px;
  }
}
.header {

margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}
.button {
  color: #fff;
  background-color: #1cb064;
  border-color: #1cb064;
}
.el-table {
  border-radius: 4px;
}
.footet-pagination{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .total{
        margin-left: 20px;
        color: #666666;
       
    }

}


